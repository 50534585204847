<template>
  <div class="container py-4">
    <div class="row g-3">
      <div class="col-6 col-md-4 col-lg-3" v-for="item in collections" :key="item.id">
        <div class="full_product_box_item">
          <a href="" @click.prevent="goTo({name: 'shop', query:{collection: item.collections ?? [item.id]}})">
            <img :src="getPopImg(item)" @error="imageUrlAlt">
          </a>
        </div>
        <div class="full_product_box_item_name">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CollectionsView",
  data() {
    return {
      defaultImg: require("@/assets/images/img-default.png"),
      valueBox: {},
      collections: [],
    };
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
    goTo(rl) {
      this.$router.push(rl);
    },
    getPopImg(item) {
      let randomNumber = Math.floor(Math.random() * 1000) + 1;
      return process.env.VUE_APP_CDN + '/collections/' + (item.value ?? item.id) + '.jpg?ver=' + randomNumber
    }
  },
  mounted() {
    this.$store.dispatch('product/collections', {perPage: 1000, orderCol: 'name', orderDir: 'ASC'}).then(rez => {
      this.collections = rez.data
    })
  }
}
</script>

<style scoped>

</style>